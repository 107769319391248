import React, { useEffect, useState } from 'react';

function MyComponent() {
   const [Questions, setQuestions] = useState([]);

   const [qlist, setqlst] = useState([]);
   //const [isLoading, setIsLoading] = useState('');

   useEffect(() => {
      fetch('http://192.168.1.121:83/api/Booking/gettripfeedbackquestion')
         .then((result) => {
            result.json().then((resp) => {
               //console.warn(resp);
               setQuestions(resp);
               setqlst(resp.questionlst);
            })

         })
   }, [])

   console.warn("result--1", Questions);

   console.warn("Question list-", Questions.questionlst);

   console.warn('questionlst-2', qlist);



   async function BookNow() {

      const inputvalues = {
         // CallerID: "9711825219",
         // CallerName: "Pankaj Kumar Rana",
         // TaxiCategoryId: "2",
         // TaxiCategory: "Sedan",
         // RatePlanId: "1464266",
         // TariffId: "1464266",
         // Tariff: "AIRPORT TO CITY",
         // RequestPickUpTime: "2023-09-10 12:20:00",
         // NoOfDays: "1",
         // PickupAddress: "KR Puram",
         // PickupMessage: "",
         // Pickuplat: "77.334",
         // Pickuplon: "13.32",
         // DropOffAddress: "Mekhricircle",
         // DropOfflat: "77.98",
         // DropOfflon: "13.999",
         // TypeOfTripId: "1464266",
         // Noofpassengers: "3",
         // NoOFVehicles: "1",
         // GuestEmailId: "pankajkumar.r@refex.co.in",
         // GuestMobileNum: "9711825219",
         // paymenttypeid: "1",
         // paymenttype: "Cash",
         // email: "sarika@refex.co.in",

         CallerID: "9711825219",
         CallerName: "Pankaj Rana",
         TaxiCategoryId: "2",
         TaxiCategory: "HATCH BACK",
         RatePlanId: "3",
         TariffId: "3",
         Tariff: "Airport To City",
         RequestPickUpTime: "2023-09-10 12:20:00",
         NoOfDays: "1",
         PickupAddress: "No.1st Cross, Achaiah Chetty Layout, Achaiah Chetty Arcade, Sadashiva Nagar, Mekhri Circle Junction, 19, AH47, Raj Mahal Vilas Extension, Armane Nagar, Bengaluru, Karnataka 560080, India",
         PickupMessage: "",
         Pickuplat: "13.0143537",
         Pickuplon: "77.5832109",
         DropOffAddress: "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka, India",
         DropOfflat: "13.1989089",
         DropOfflon: "77.70681309999999",
         TypeOfTripId: "0",
         Noofpassengers: "3",
         NoOFVehicles: "1",
         GuestEmailId: "0",
         GuestMobileNum: "0",
         paymenttypeid: "1",
         paymenttype: "Cash",
         email: "pankajkumar.r@refex.com",
      };
      debugger;

      try {
         let result = await fetch(
            "https://localhost:7291/api/Booking/bookairportvechicle",
            {
               method: "POST",
               headers: {
                  "Content-Type": "application/json",
                  Accept: "text/plain",
               },
               body: JSON.stringify(inputvalues),
            }
         );
         result = await result.json();
         console.log(result);
      }
      catch (error) {
         console.log(error);
      }

      // validate();

   }


   const getData=(data)=>{
      console.log(data);
      return fetch(`http://localhost:5000/api/payment`,{
         method:"POST",
         headers:{
            Accept:"application/json",
            "Content-Type":"application/json"
         },
         body:JSON.stringify(data)
      }).then(response=>response.json()).catch(err=>console.log(err))
   }

   const makePayment = () => {
      alert('pay');
      getData({amount:500,email:'pankajkumar.r@refex.co.in'}).then(response=>console.log(response))
   }

   return (
      <div>
         <div>
            <table className="table table-bordered">
               <thead>
                  <tr>
                     <th>S.N</th>
                     <th>Question No.</th>
                     <th>Question</th>

                  </tr>
               </thead>
               <tbody>
                  {
                     qlist.map((data, index) => (
                        <tr>
                           <td>{index + 1}.</td>
                           <td>{data.questionId}</td>
                           <td>{data.question}</td>

                        </tr>
                        // <li>{data.questionId}  {data.question}</li>
                     ))
                  }
               </tbody>
            </table>
         </div>

         <hr />

         <p>Question List</p>

         {Questions.statusCode}<br />
         {Questions.statusMessage}
         {/* <h1>{qlist.question}</h1> */}

         <ul>
            {qlist.map((data) => (
               <li>{data.questionId}  {data.question}</li>
            ))}
         </ul>

         <div>
            <input
               type="button"
               onClick={BookNow}
               className="btn btn-warning"
               value="Book a Ride"
            />
         </div>
         {/* pay using paytm */}
         <div style={{ margin: '20px' }}>
            <input
               type="button"
               onClick={makePayment}
               className="btn btn-success"
               value="PAY USING PAYTM"
            />

         </div>
      </div>
   )
}
export default MyComponent;