import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Login from '../Auth/Login';
import MasterPage from './MasterPage';
import Dashboard from '../Pages/Dashboard';
import Booking from '../Pages/Booking';
import Registration from '../Auth/Registration';
import UpcomingTrips from '../Pages/UpcomingTrips';
import Completed from '../Pages/Completed';
import Rejected from '../Pages/Rejected';
import Canceled from '../Pages/Canceled';
import ProtectedRoute from './ProtectedRoute';
import Profile from '../Pages/Profile';
import MyComponent from '../Pages/MyComponent';
import RefundPolicy from '../Pages/RefundPolicy';
import Bookingconfirm from '../Pages/Bookingconfirm';
import Success from '../Pages/payment/Success';
import Failure from '../Pages/payment/Failure';


export default class AppRouters extends Component {
   
    render() {
        return (
            <div>
                <Router>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/Login" element={<Login />} />
                        <Route path="/Registration" element={<Registration />} />
                        <Route path="/RefundPolicy" element={<RefundPolicy />} />
                        <Route path='/Success' element={<Success />} />
                        <Route path='/Failure' element={<Failure />} />
                        
                        <Route path='/MyComponent' element={<MyComponent />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path="/Dashboard" element={<Dashboard />} />
                            <Route path="/Booking" element={<Booking />} />
                            <Route path="/Bookingconfirm" element={<Bookingconfirm />} />
                            <Route path='/Upcomingtrips' element={<UpcomingTrips />} />
                            <Route path='/Completed' element={<Completed />} />
                            <Route path='/Rejected' element={<Rejected />} />
                            <Route path='/Canceled' element={<Canceled />} />
                            <Route path='/Profile' element={<Profile />} />
                        </Route>
                    </Routes>
                </Router>
            </div>
        )
    }
}
