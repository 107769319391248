import React, { Component } from 'react'
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

  ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);

export default class BarChart extends Component {
  render() {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Incident Details City Wise',
          },
        },
      };
      const labels =  ['Bengaluru', 'Chennai', 'Pune', 'Mumbai', 'Delhi', 'Gurugram'];
      const data = {
        labels,
        datasets: [
          {
            label: 'Accident With Killed',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Accident With Injured',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };
    return (
      <div>
        <Bar options={options} data={data} />
      </div>
    )
  }
}