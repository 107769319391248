import React, { Component } from 'react'
import MasterPage from '../Templates/MasterPage'
import Footer from '../Templates/Footer'
import LineChart from './Charts/LineChart'
import BarChart from './Charts/BarChart'
import PieChart from './Charts/PieChart'
import DoughnutChart from './Charts/DoughnutChart'
import Killedbyvehicletype from './Charts/Killedbyvehicletype'

export default class Dashboard extends Component {

    render() {
        return (
            <>
                <div>
                    <MasterPage />
                </div>
                <div>
                    <div className="content-wrapper" style={{ minHeight: '704.8px' }}>
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Dashboard</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Dashboard</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>{/* /.container-fluid */}
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <div className="container-fluid">
                                {/* Info boxes */}
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-info elevation-1"><i className="fa fa-solid fa-car" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Total Trips</span>
                                                <span className="info-box-number">
                                                    0
                                                    <small>%</small>
                                                </span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="info-box mb-3">
                                            <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-solid fa-car" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Upcoming Trips</span>
                                                <span className="info-box-number">0</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                    {/* fix for small devices only */}
                                    <div className="clearfix hidden-md-up" />
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="info-box mb-3">
                                            <span className="info-box-icon bg-success elevation-1"><i className="fa fa-shopping-cart" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Completed Trips</span>
                                                <span className="info-box-number">0</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="info-box mb-3">
                                            <span className="info-box-icon bg-danger elevation-1"><i className="fa fa-solid fa-car" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Rejected Trips</span>
                                                <span className="info-box-number">2,000</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="card card-secondary">
                                            <div className="card-header">
                                                <h3 className="card-title">Incident Report Monthly</h3>

                                            </div>
                                            <div className="card-body" style={{ display: 'block' }}>
                                                <>
                                                    <LineChart />
                                                </>
                                            </div>
                                            {/* /.card-body */}
                                        </div>
                                        {/* /.card */}
                                    </div>

                                    <div className="col-md-6">
                                        <div className="card card-secondary">
                                            <div className="card-header">
                                                <h3 className="card-title">Incident Report City Wise</h3>

                                            </div>
                                            <div className="card-body" style={{ display: 'block' }}>
                                                <>
                                                    <BarChart />
                                                </>
                                            </div>
                                            {/* /.card-body */}
                                        </div>
                                        {/* /.card */}
                                    </div>


                                </div>

                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="card card-secondary">
                                            <div className="card-header">
                                                <h3 className="card-title">Injured by vehicle type</h3>
                                            </div>
                                            <div className="card-body" style={{ display: 'block' }}>
                                                <PieChart />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card card-secondary">
                                            <div className="card-header">
                                                <h3 className="card-title">Killed by vehicle type</h3>
                                            </div>
                                            <div className="card-body" style={{ display: 'block' }}>
                                                <Killedbyvehicletype />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card card-secondary">
                                            <div className="card-header">
                                                <h3 className="card-title">Area wise incident</h3>
                                            </div>
                                            <div className="card-body" style={{ display: 'block' }}>
                                                <DoughnutChart />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/*/. container-fluid */}
                        </section>
                        {/* /.content */}
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </>
        )
    }
}
