import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { server } from '../server'
import Swal from 'sweetalert2'


import { Company_Id } from '../Helper'
import { Corporate_Id } from '../Helper'
import { Corporate_Name } from '../Helper'
import { Vendor_Id } from '../Helper'
import { Vendor_Name } from '../Helper'

function Registration() {
  //================ Local record ==================
  // const initialValues = {
  //   Name: "", Email: "", MobileNo: "", Gender: "", DeviceTokenId: "0", DeviceType: "o3web",
  //   UserPass: "", UserConfirmPass: "",
  //   CorporateId: "13611", CorporateName: "O3MPLRetail",VendorId:"2584"
  // }

  //========== Live record ==============
  const initialValues = {
    Name: "", Email: "", MobileNo: "", Gender: "", DeviceTokenId: "0", DeviceType: "o3web",
    UserPass: "", UserConfirmPass: "",
    CorporateId: "13647", CorporateName: "O3MPLRetail",VendorId:"2587"
  }


  const [formvalue, setFormValue] = useState(initialValues);

  const [formErrors, setFormErrors] = useState({});
  const [IsSubmit, setIsSubmit] = useState(false);

  const handChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formvalue, [name]: value });
    console.log(formvalue);
  }

  async function userRegistration(formvalue) {

    let result = await fetch(`${server}/api/O3Mobility/o3userregistration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(formvalue)

    });
    result = await result.json();
    //  if(result)
    if (result.statusCode === 200) {
      Swal.fire(
        'Successfully Done!',
        'Thank you for registration, Please go to login page and login',
        'success'
      )
    }
    console.log(result.statusCode);
  }



  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formvalue));
    setIsSubmit(true);
  }
  useEffect(() => {
    //console.log(formErrors);
    if (Object.keys(formErrors).length == 0 && IsSubmit) {
      console.log(formvalue);
      userRegistration(formvalue);
    }
  }, [formErrors]);

  const validate = (value) => {
    const errors = {};
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!value.Name) {
      errors.Name = "Name is required";
    }
    if (!value.Email) {
      errors.Email = "Email required";
    }
    // else if(regex.test(value.Email)){
    //   errors.Email="This is not a valid email format";
    // }
    if (!value.MobileNo) {
      errors.MobileNo = "Mobile No. required";
    }
    if (!value.Gender) {
      errors.Gender = "Gender required";
    }
    if (!value.UserPass) {
      errors.UserPass = "Password is required";
    }
    if (!value.UserConfirmPass) {
      errors.UserConfirmPass = "Confirm password is required";
    }
    return errors;
  };

  return (
    <div>
      <div className='hold-transition login-page'>

        <form onSubmit={handleSubmit}>
          <div className="login-box">
            <div className="card card-outline card-warning">
              <div className="card-header text-center">
                <img src="dist/img/logo.webp" alt="logo" style={{ width: '250px' }} />
              </div>
              <div className="card-body">

                {
                  // Object.keys(formErrors).length==0 && IsSubmit ? (
                  //   <div className="alert alert-success">Register Successfully</div>
                  //   ): ( 
                  //     <div></div>
                  //    )
                }

                <p className="login-box-msg">Register</p>
                <form >
                  <div className="input-group mb-3">
                    <input type="text" id='username' className="form-control" placeholder="Name"
                      name="Name"
                      value={formvalue.Name}
                      onChange={handChange}

                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-user" />
                      </div>
                    </div>

                  </div>
                  <p className='text-danger'>{formErrors.Name}</p>
                  <div className="input-group mb-3">
                    <input type="email" id='useremail' className="form-control" placeholder="Email"
                      name="Email"
                      value={formvalue.Email}
                      onChange={handChange}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <p className='text-danger'>{formErrors.Email}</p>
                  <div className="input-group mb-3">
                    <input id='usermobileno' type="text" className="form-control" placeholder="Mobile No"
                      name="MobileNo"
                      value={formvalue.MobileNo}
                      onChange={handChange}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fa fa-mobile" />
                      </div>
                    </div>
                  </div>
                  <p className='text-danger'>{formErrors.MobileNo}</p>
                  <div className="input-group mb-3">
                    {/* <input type="password" className="form-control" placeholder="Password" />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div> */}
                    <select id='usergender' className="form-control"
                      name="Gender"
                      value={formvalue.Gender}
                      onChange={handChange}
                    >
                      <option>Select your Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>

                  </div>
                  <p className='text-danger'>{formErrors.Gender}</p>
                  <div className="input-group mb-3">
                    <input id='userpassword' type="password" className="form-control" placeholder="Password"
                      name="UserPass"
                      value={formvalue.UserPass}
                      onChange={handChange}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <p className='text-danger'>{formErrors.UserPass}</p>
                  <div className="input-group mb-3">
                    <input id='userconfirmpassword' type="password" className="form-control" placeholder="Confirm Password"
                      name="UserConfirmPass"
                      value={formvalue.UserConfirmPass}
                      onChange={handChange}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>

                  <div className="row">

                    <div className="col-8">
                      <p>{formErrors.UserConfirmPass}</p>
                      <div className="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">
                          Remember Me
                        </label>
                      </div>
                    </div>

                    <div className="col-4">
                      <Link to="/" className="">Login</Link>
                    </div>
                  </div>
                </form>
                <div className="social-auth-links text-center mt-2 mb-3">
                </div>

                <p className="mb-0">
                  <button className="btn btn-warning btn-block">Register Now</button>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default Registration
