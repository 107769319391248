import { React, useState, useEffect } from 'react'
import MasterPage from '../Templates/MasterPage'
import Footer from '../Templates/Footer'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { server } from '../server'

import { Company_Id } from '../Helper'
import { Corporate_Id } from '../Helper'
import { Corporate_Name } from '../Helper'
import { Vendor_Id } from '../Helper'
import { Vendor_Name } from '../Helper'

export default function Profile() {
    let userMobNo = localStorage.getItem('contactNo');
    const [getprofile, setprofile] = useState([]);
    const userdetails = { MobileNo: userMobNo }
    const [userId, setUserID] = useState(userdetails);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${server}/api/O3Mobility/getuserprofile/${Corporate_Id}/${Corporate_Name}/${Vendor_Id}/${Vendor_Name}/${userMobNo}`)
            .then((result) => {
                result.json().then((resp) => {
                    var profilelist = [...resp.profileDetails];
                    setprofile(...profilelist);
                })

            })
    }, [])

    const gender = () => {
        if (getprofile.gender === 'M' || getprofile==='Male') {
            return 'Male';
        }
        else if (getprofile.gender === 'F'|| getprofile.gender==='Female') {
            return 'Female'
        }
        debugger;
        // else {
        //     return 'Other'
        // }
    }

    async function deleteUserData() {
        let result = await fetch(`${server}/api/Registration/deleteprofile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/plain"
            },
            body: JSON.stringify(userId)
        });
        result = await result.json();
        if ((result.statusCode === 200) && (result.statusMessage === 'Account Deleted successfully')) {
            Swal.fire(
                'Deleted!',
                'Your profile has been deleted.',
                'success'
            )
            navigate('/');
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: result.StatusMessage,
            })
        }
    }

    const deleteProfile = (e) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserData();
            }
        })
    }
    const [isOpen, setIsOpen] = useState(false);
    const changepass = () => {
        
        setIsOpen(true);
    }
    const updatepass=()=>{
       
        setIsOpen(false);
    }
    return (
        <>
            <div>
                <MasterPage />
            </div>
            <div>
                <div className="content-wrapper" style={{ minHeight: '704.8px' }}>
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>User Profile</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Profile</li>
                                    </ol>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">

                            <div className="card card-warning">
                                <div className="card-header">
                                    <h3 className="card-title">User Profile</h3>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <strong><i class="fas fa-user mr-1"></i> Name</strong>

                                            <p class="text-muted">
                                                {getprofile.name}
                                            </p>

                                            <hr />

                                        </div>
                                        <div className="col-md-3">
                                            <strong><i class="fas fa-email mr-1"></i> Email Id</strong>

                                            <p class="text-muted">
                                                {getprofile.email}
                                            </p>

                                            <hr />

                                        </div>
                                        <div className="col-md-3">
                                            <strong><i class="fas fa-mob mr-1"></i> Contact No</strong>

                                            <p class="text-muted">
                                                {getprofile.mobileNo}
                                            </p>

                                            <hr />

                                        </div>
                                        <div className="col-md-3">
                                            <strong><i class="fas fa-gender mr-1"></i> Gender</strong>

                                            <p class="text-muted">
                                                {getprofile.gender}
                                                {/* {gender()} */}
                                            </p>

                                            <hr />

                                        </div>
                                    </div>
                                    {/* change password */}
                                    <div className='row'>
                                        <div className="col-md-5">
                                            <strong><i class="fas fa-eye mr-1"></i> Password</strong> &nbsp;&nbsp;
                                            
                                            <strong><input style={{ border: 'none' }} type='password' value={getprofile.name}></input></strong>
                                            {isOpen && <><input type='password' placeholder='Enter new password'></input></>}

                                        </div>
                                        <div className="col-md-4">
                                            {
                                                isOpen===true?(<div> <button onClick={updatepass} className="btn btn-success btn-sm">Change Password</button></div>)
                                                :(<div> <button onClick={changepass} className="btn btn-warning btn-sm">Update Password</button></div>)
                                            }
                                           
                                        </div>
                                        <div className="col-md-3">



                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4'>
                                            <button onClick={deleteProfile} className="btn btn-danger">Delete</button>

                                        </div>
                                        <div className='col-md-4'></div>
                                    </div>
                                    {/* <div className='row'>

                                    <div className="col-md-3">
                                            <strong><i class="fas fa-user mr-1"></i> Password</strong>

                                            <p class="text-muted">
                                               Panaj Kumar
                                            </p>

                                            <hr />

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>To Location</label>
                                                <div className="input-group date" id="reservationdatetime" data-target-input="nearest">
                                                    <input type="text" className="form-control datetimepicker-input" data-target="#reservationdatetime" />

                                                </div>
                                            </div>

                                        </div>
                                    </div> */}

                                </div>
                                {/* /.card-body */}
                            </div>




                        </div>{/*/. container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}
