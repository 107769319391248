import React from "react";
import { Link, useNavigate } from 'react-router-dom';
export default function Failure() {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
const tranId = urlParams.get('txnId');
console.log('trans-status',tranId);
    return (
        <>
            <div className="container" style={{marginTop:'100px'}}>
                <div className="row text-center">
                    <div className="col-sm-2 col-sm-offset-3"></div>
                    <div className="col-sm-8 col-sm-offset-3">
                        <h2>Error 404. &nbsp;Oops you've have encountered an error</h2>
                        <p>
                            It apperrs that Either something went wrong or the page doesn't exist anymore..<br />
                        </p>
                        <p>
                            If you have any questions, please contact our Technical Support department. rgml.support@refex.co.in 
                        </p>
                    </div>

                    <div className="col-sm-2 col-sm-offset-3"></div>
                </div>
            </div>

        </>
    )
}
