import React, { Component } from 'react'
import {Chart as ChartJS, CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip, Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);

export default class LineChart extends Component {
  componentDidMount() {
    // const fetchData = async () => {
    //    const response = await fetch('http://localhost:8080/api/getyearincident')
    //    const jsonResponse = await response.json();
    //    console.log(jsonResponse);
    //   const obj={};
    //    for(var i=0;i<jsonResponse.incidentDetails.length;i++){
    //     obj[i]=jsonResponse.incidentDetails[i].Months
       
    //    }
    //    console.log('Months:',obj);
   // }
    //fetchData();
 }

  render() {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Incident Details Monthly Wise',
          },

        },
        scales:{
          x:{
            title:{
              display:true,
              text:'Months of Year',
              color:'red'
            }
          },
          y:{
            title:{
              display:true,
              text:'Number of Pasanger',
              color:'red'
            }
          }
        }
      };
      const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];
      const data = {
        labels,
        datasets: [
          {
            label: 'Accident With Killed',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Accident With Injured',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };
    return (
      <div>
        <Line options={options} data={data} />
      </div>
    )
  }
}
