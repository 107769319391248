import React, { Component } from 'react'
import TopMenu from './TopMenu'
import LeftMenu from './LeftMenu'

export default class MasterPage extends Component {
  render() {
    return (
      <div>
        <TopMenu />
        <LeftMenu />

        {/* Control Sidebar */}
        <aside className="control-sidebar control-sidebar-dark">
          {/* Control sidebar content goes here */}
        </aside>
        {/* /.control-sidebar */}

      </div>
    )
  }
}
