//import React from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';
export default function Success() {

const navigate = useNavigate();

const urlParams = new URLSearchParams(window.location.search);
const tranId = urlParams.get('txnId');
console.log('trans-status',tranId);

//testing code

// const [responseData, setResponseData] = useState(null);

// const postData = async () => {
//     debugger;

//     try {
//         const token = 'Basic N2FmNjMzZjNjNGRiOTQ0YzYwMmNkMTRhNzM3N2FiZTY0MDFjYTdiN2ExZDllYTlkMTcyNTdlOTlkZTUxYmI0MjplMDU0ZTBmYTZmOTJhZWY3NjIzNWIzMzc1YjNlNjdjYzJmNDVjODM2NTQ3MjdiMmMzMWJkMTVmYWE1OGQzYmM3'; // Replace this with your actual authorization token

//         const response = await axios.post(
//             'https://bialo3mpl.eveelz.in/api/o3/v1/paid',
//             {
//                 // Your request body data here
//                 "passenger": {
//                     "name": "Pankaj Rana2",
//                     "email": "pankajkumar.r@refex.co.in",
//                     "phone_number": "9972594909",
//                     "country_code": "91"
//                   },
//                   "partner_reference_number": "389385C2-2E8C-4854-B46E-06BF79BAA8FD",
//                   "order_reference_number": "T01234TEDYY12",
//                   "total_fare": 2210,
//                   "amount_to_be_collected": 200,
//                   "vendor_id": "2587",
//                   "partner_name": "O3MPLRetail"
//             },
//             {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             }
//         );
//         // Handle response data
//         console.log(response.data);
//     } catch (error) {
//         // Handle error
//         console.log(error);
//         console.error('Error:', error);
//     }

// };

//testing code 




    return (
        <>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-3 col-sm-offset-3"></div>
                    <div className="col-sm-6 col-sm-offset-3">
                        <br /><br /> <h2 style={{ color: '#0fad00' }}>Success</h2>

                        <p style={{ fontSize: 20, color: '#5C5C5C' }}>Thank you !!
                            <br />
                            {/* Your Transaction id is <b>{trnId}</b>. */}
                            <br />
                            Your booking number has been sent to your register email id.
                        </p>

                        <Link className="btn btn-success" to="/Booking">Back</Link>
                        <br /><br />
                        {/* <button onClick={postData} className="btn btn-outline-info btn-block">Confirm And Pay Cash </button> */}
                    </div>
                    <div className="col-sm-3 col-sm-offset-3"></div>
                </div>
            </div>

        </>
    )
}
