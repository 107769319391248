
export const GetCurrentDateTime = () => {

    const timestamp = Date.now();
    const date = new Date(timestamp);

    // Extract date components
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    // Extract time components
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    // Format the date and time
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;

    //console.log(formattedDateTime);
}

//-- Local details.........==============
// export const Company_Id = 13960;
// export const Corporate_Id = 13611;
// export const Corporate_Name = 'O3MPLRetail';
// export const Vendor_Id = 2584;
// export const Vendor_Name = 'O3MPL';

//-- Live details-----=========================
export const Company_Id = 13996;
export const Corporate_Id = 13647;
export const Corporate_Name = 'O3MPLRetail';
export const Vendor_Id = 2587;
export const Vendor_Name = 'O3MPL';

