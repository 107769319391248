
import React from 'react'
import { useNavigate, Link } from 'react-router-dom';

function RefundPolicy() {

    return (
        <div>

            <>
                <div>
                    <div className="container-fluid p-5 bg-warning text-white text-center">
                        <img src="/dist/img/logo.webp" alt="logo" style={{ width: 350 }} />

                        <h2 style={{ textTransform: 'uppercase' }}>Our Terms Condition and Refund Policy</h2>
                    </div>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>1. Introduction</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    This web portal  (“Application or App”) is owned and operated by Refex Green Mobility Limited, a company incorporated in India under the Companies Act, 2013, having its registered office at Ground Floor, Bascon Futura IT Park, New No 10/2,Old No 56L, Venkat Narayana Road, T Nagar Chennai 600017, Tamil Nadu (“Company”).
                                    The Company is engaged in the business of providing e-mobility solutions to corporate’
                                    employees and other commuters vide access to its internet and mobile-based Application (“Services”).
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    This Privacy Policy (“Privacy Policy”) explains how the Company collects, processes, uses, shares and protects information about you. It also tells you how you can access and update your information and make certain choices about how your information is used. This document is published in accordance with the provisions of the Information Technology Act, 2000 (and the rules made thereunder) and the Digital Personal Data Protection Act, 2023. We request you to go through this Privacy Policy carefully before you decide to access this Application.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    For the purposes of this Privacy Policy, the words “us”, “we”, and “our” refer to the Company and all references to “you”, “your” or “user”, as applicable mean the person who accesses, uses and/or participates in the Application in any manner or capacity. All references to “Employer” shall mean the employer of the user with whom the Company has entered into an agreement for the purpose of providing employee transportation solutions vide access to the Application.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    The protection and security of your Personal Information as defined below, is one of our top priorities and we have taken all necessary and reasonable measures to protect the confidentiality of such Personal Information and its transmission through the internet.
                                </p>
                                <h3>2.	Definition</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    Refex Green Mobility (RGML) is a platform, providing online VEHICLE BOOKING SYSTEM, to
                                    interact Consumers, who looking for various type of vehicle booking services with Service
                                    providers.
                                </p>


                                <h3>3.Terms and conditions </h3>
                                <p style={{ textAlign: 'justify' }}>
                                    Consumers can search, select, and scheduled various type of vehicles booking available on
                                    GRML platform, according to their need, choice &amp; budget, and can be made it available at their
                                    location. Service provider can register/enlist their commercial vehicles to make it
                                    available to search and book by Consumers and deliver the services.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    In continuation of using Refex Green Mobility (RGML) platform and its services, you are agreeing to comply with and
                                    be bound by the following terms and conditions of use.
                                </p>
                                <p style={{ textAlign: 'justify' }}>


                                    1. Refex Green Mobility (RGML) own all right of all types of content available at their website and mobile
                                    Application, other than content you own.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    2. During using our services, you are agreeing to receive electronic communication from us,
                                    such as e-mails, texts, notifications, or notices and messages from Refex Green Mobility (RGML).
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    3. Considering user’s safety, users are expected to select pick up and drop location as
                                    public area and same should be safe in all respect to your partner. If, your partner is
                                    feeling that, selected location is not safe, they can refuse to pick up and/or drop for that
                                    location. Users can also seek identity proof of their partner as well as vehicle registration
                                    related document, and you are agreed that you are liable to provide the asked
                                    proof/document. You are also agreed that, if, cancellation of confirmed booking attempt
                                    by you partner, due to selection of unsafe location and/or refusal to provide proof of
                                    identity proof and/or vehicle registration related document by you, cancellation charges
                                    is applicable and cancellation/convenience refund (100% amount of the
                                    advance/booking/service charges) shall not be applicable for you.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    4. All types of content available on Refex Green Mobility (RGML) platform is just for user’s basic information and
                                    same may be change/update/remove without any prior notice. Neither we and our
                                    representative nor any third party(s) providing any warranty or guarantee for the
                                    accuracy, performance, completeness or suitability of the information, services and
                                    materials offering on this platform for any particular purpose. During using our website
                                    and platform, you acknowledge and agree that such information’s and materials may
                                    contain inaccuracies or errors and we expressly exclude liability for any such
                                    inaccuracies or errors to the fullest extent permitted by law. You are using of any/all
                                    information, services or materials on this platform is entirely at your own risk, for which
                                    we shall not be liable.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    5. For using Refex Green Mobility (RGML) Services, you need a Refex Green Mobility (RGML) account in order to use our services. In
                                    consideration to protection of your account you are required to keep your login
                                    credential confidential. Also, you are not authorized to share your account credential or
                                    allow to access your account to any third person or parties or entity. You are fully
                                    responsible for the activities happening through your Refex Green Mobility (RGML) Account. We are reserving
                                    the right to suspend your services or complete account, if found any type of
                                    unauthorized uses, subspecies activity or violation of terms &amp; conditions and privacy
                                    policy.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    6. Towards, improving our services and protect your privacy, we may require treating your
                                    personal information/data saved in our record in accordance with our privacy policy, by
                                    using our servicers users are agree with the same.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    7. All type of content available on Refex Green Mobility (RGML) platform is either copyrighted by us or by third
                                    parties, which contents is being displaying here, we are expecting by you to respect all
                                    such type of copyrights and never attempt to infringement of copyright.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    8. During using or providing services through Refex Green Mobility (RGML) platform, you are agreeing to follow
                                    and abide all the Rules/regulations/act/notice issued by local/district/state/central
                                    government as per Indian government law and you are not allowed to use our service in

                                    manner of biased, discrimination, nuisance, annoyance, inconvenience, Crime, fraud,
                                    personal or property damage.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    9. During using our services, you are undertaking that, it is your responsibility to ensure
                                    that, you are using the services as per your requirements, needs and expectation,
                                    neither we and our representative nor any third parties make any commitment to the
                                    content, nature, reliability or safety of the service.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    10. During using our services, you are undertaking and agree that, you are the only
                                    responsible for any type of personal or property damages/losses regarding to you and/or
                                    your vehicles (s) and also for dispute or claim raised by you/your partner or any third
                                    parties against you and/or your vehicles in any form and/or at any stage during
                                    providing and/or using the services through Refex Green Mobility (RGML) platform. However, Refex Green Mobility (RGML) is only
                                    providing internet platform and Refex Green Mobility (RGML) &amp; their representatives are not part of this type
                                    of incident anyhow.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    11. During using our services, you are agreeing to raise complaints about our product and
                                    service, if any, within 7 days of completion of services.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    12. In certain instances, we and/or our partners and/or your partners require you to provide
                                    proof of identity and/or additional information from your side to access/use and/or
                                    providing the services. In case of you are refusing to provide proof of identity and/or
                                    asked information, then you are agreeing that you may be denied to accessing/using
                                    and/or providing the services. Also, you are agreeing that if any booking/services has
                                    been cancelled due to refusal of providing proof of identity/information by you, then,
                                    cancellation charges are applicable and cancellation/convenience refund (100% amount
                                    of the advance/booking/service charges) shall not be applicable for you.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    13. During using our services and/or providing services through us, users are agreeing to
                                    adherence about time scheduled. In case of you are not available at the selected
                                    location on scheduled time, then your partner has reserved the rights to cancel the
                                    booking/services after certain limit of waiting period (15 Minutes of scheduled date and
                                    time). You are also agreeing that, if booking/services has been cancelled due to
                                    inconsistency of time schedule, then cancellation charges are applicable and
                                    cancellation/convenience refund (100% amount of the advance/booking/service
                                    charges) shall not be applicable for you.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    14. We are constantly working towards improving our service and your experience with us.
                                    In line with the same, we may change, add, or remove content, offering, functionalities,
                                    features, terms of use, terms of services, privacy policy, price of product, services
                                    charges, convenience charges, cancellation charges, advance booking amount, plans,
                                    incentives, commissions, offer &amp; rewards without any prior notice. You are expected to
                                    read, understand, and comply with those changes and updates. By using our services,
                                    you accept those changes &amp; updates and agree to comply with them.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    15. Considering types of services offering, it may have additional terms and conditions
                                    applicable, users are agreeing to comply with and be bound by the same.
                                </p>

                                <h3>Security deposit</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    During Providing Services through Refex Green Mobility (RGML) platform, users are agreeing to deposit and maintain
                                    the security deposit amount, and Refex Green Mobility (RGML) has also rights to collect security deposit amount from
                                    the collection of booking advance amount. Whether security deposit amount shall be
                                    refund/pay to users at the time of discontinuation of enlistment services only, if, and when
                                    happened.
                                </p>

                                <h3> Convenience fee </h3>
                                <p style={{ textAlign: 'justify' }}>
                                    During using services through Refex Green Mobility (RGML) platform, users are agreeing to pay Convenience fee
                                    charged by Refex Green Mobility (RGML).
                                </p>
                                <h3>Booking advance amount</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    During using Services through Refex Green Mobility (RGML) platform, users are agreeing to pay booking advance
                                    amount.
                                </p>
                                <h3>Booking Cancellation and Refund</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    During using services through Refex Green Mobility (RGML) platform, users can cancel confirmed booking and no
                                    cancellation charges will applicable, also advanced paid amount will be refundable subject to
                                    booking cancellation will not happened during locking period.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Locking period for cancellation of bookings:
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    During using our services, you are undertaking and agree to follow below mentioned terms of
                                    locking period during cancellation of confirmed bookings.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Booking Initiated before 3-6 Hours before Scheduled time – Booking Cancellation is not
                                    applicable.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Booking initiated before 6-12 Hours before Scheduled time - Locking period will be 4 Hours
                                    before Scheduled time.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Booking Initiated before 12-24 Hours before Scheduled time - Locking period will be 8 Hours
                                    before Scheduled time.
                                    Booking Initiated before 24-48 Hours before Scheduled time - Locking period will be 12 Hours
                                    before Scheduled time.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Booking Initiated before More than 48 Hours before Scheduled time - Locking period will be 24
                                    Hours before Scheduled time.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    During Locking period, cancellation of booking is not allowed. And if, you are cancelling bookings
                                    during looking period, then then cancellation charges are applicable and
                                    cancellation/convenience refund (100% amount of the advance/booking/service charges) shall
                                    not be applicable for you.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Timeline to respond the booking and enquires.</p>
                                <p style={{ textAlign: 'justify' }}>
                                    During using our services, users are undertaking and agreeing to adherence about Timeline to
                                    respond the booking and enquires. You are agreeing and bound to respond the booking and
                                    enquires within 30 Minutes. In absence of your response within 30 minutes, your Booking
                                    and/or enquiries will considered as expired and no further extension will be applicable.

                                </p>
                            </div>

                        </div>
                        <div className="row">
                            <br /> <br /> <br />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <Link to="/" className="text-center text-danger btn btn-outline-warning">Back </Link>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                    </div>
                </div>

            </>





        </div>
    )
}

export default RefundPolicy
