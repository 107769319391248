import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router';

const UseAuth = () => {
    var logedIn = localStorage.getItem('isLogin');
    var isLogined = false;
    if (logedIn === 'true') {
        isLogined = true;
    }
    else {
        isLogined = false;
    }
    const user = {
        IsLogin: isLogined
    };
    return user && user.IsLogin;
};

export default function ProtectedRoute() {
    const isAuth = UseAuth();
    return isAuth ? <Outlet /> : <Navigate to="/" />
};
