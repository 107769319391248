import AppRouters from './Templates/AppRouters';
import React from 'react';


function App() {
  return (
    <div>
     <AppRouters/>
    </div>
  );
}

export default App;
