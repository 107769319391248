import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default class Footer extends Component {
    render() {
        return (
            <div>
                {/* Main Footer */}
                <footer className="main-footer">
                    <strong>Copyright © 2023-2024 <a href="https://eveelz.in" target='_blank'>Refex Green Mobility Limited (RGML)</a>.</strong>
                    All rights reserved.
                    
                    <div className="float-right d-none d-sm-inline-block">
                    <span>
                    <Link to="/RefundPolicy" target='_blank' className="text-center text-danger" style={{fontSize:'14px', color:'#5b5b55 !important'}}>Privacy Policy</Link>
                    </span> &nbsp;&nbsp;
                        <b>Version</b> 1.0.0
                    </div>
                </footer>

            </div>
        )
    }
}
